import * as React from "react";
import { Items } from "./Items";
import { ReactComponent as Bot1 } from "./images/bot1.svg";
import { ReactComponent as Bot2 } from "./images/bot2.svg";
import { ReactComponent as Bot3 } from "./images/bot3.svg";
import { ReactComponent as Bot4 } from "./images/bot4.svg";
import bot5 from "./images/bot5.png";

import styles from "./Bot.module.scss";

type IProps = {};

const BotSection: React.FC<IProps> = () => {
  return (
    <section className={styles.Section}>
      <div className={styles.Container}>
        <div className={styles.Items}>
          <h2 id="contacts" className={styles.Header}>
            Телеграм-бот для рассылки ролей без карточек
          </h2>
          <Items
            itemFirst={{
              firstIcon: <Bot1 />,
              firstText: "Автоматическая авторизация при переходе на сайт",
              secondIcon: <Bot2 />,
              secondText: "Получение роли во время игры",
            }}
            itemSecond={{
              firstText: "Бронирование места в игре",
              firstIcon: <Bot3 />,
              secondText: "Ответы на часто задаваемые вопросы",
              secondIcon: <Bot4 />,
            }}
            showDivider={false}
            classContainer={styles.ItemsPosition}
          />
        </div>
        <img loading="lazy" src={bot5} className={styles.Bot5Img} alt="bot" />
      </div>
    </section>
  );
};

export { BotSection };
