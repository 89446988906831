import * as React from "react";
import classNames from "classnames";
import organizer1 from "./images/organizer1.png";
import { ReactComponent as ClubCreateIcon } from "./images/organizer2.svg";
import { ReactComponent as ClubRatingIcon } from "./images/organizer3.svg";
import { ReactComponent as ClubStandingsIcon } from "./images/organizer4.svg";
import { ReactComponent as ClubCreateGameIcon } from "./images/organizer5.svg";
import organizer6 from "./images/organizer6.png";
import { ReactComponent as PrivateGameIcon } from "./images/organizer7.svg";
import { ReactComponent as ConfigGameIcon } from "./images/organizer8.svg";
import { ReactComponent as LinkLocationGame } from "./images/organizer9.svg";
import organizer10 from "./images/organizer10.png";
import organizer11 from "./images/organizer11.png";
import organizer12 from "./images/organizer12.png";
import organizer13 from "./images/organizer13.png";
import { Items } from "./Items";

import styles from "./OrganizerSection.module.scss";

type IProps = {};

const OrganizerSection: React.FC<IProps> = () => {
  return (
    <section className={styles.Section}>
      <h2 id="organizer" className={styles.Header}>
        Организаторам
      </h2>
      <div className={styles.Content}>
        <div className={styles.Club}>
          <img
            loading="lazy"
            src={organizer1}
            className={styles.Organizer1Img}
            alt="clubCreate"
          />
          <Items
            classContainer={styles.ItemsPosition}
            itemFirst={{
              firstIcon: <ClubCreateIcon />,
              firstText: "Создание клуба",
              secondIcon: <ClubRatingIcon />,
              secondText: "Оценка клуба по мнению игроков",
            }}
            itemSecond={{
              firstIcon: <ClubStandingsIcon />,
              firstText: "Клубная турнирная таблица по игракам",
              secondIcon: <ClubCreateGameIcon />,
              secondText: "Создание игр в Мафию",
            }}
          />
        </div>
        <div className={styles.Statistics}>
          <div className={styles.Header}>Просматривать статистику по клубу</div>
          <img
            loading="lazy"
            src={organizer6}
            className={styles.Organizer6Img}
            alt="statistics"
          />
        </div>
        <div className={styles.Additional}>
          <div className={styles.Items}>
            <Items
              classContainer={styles.ItemsPosition}
              itemFirst={{
                firstIcon: <PrivateGameIcon />,
                firstText: "Создавать приватные игры доступные по ссылке",
                secondIcon: <ConfigGameIcon />,
                secondText:
                  "Конфигурировать игры по количеству участников и составу ролей",
              }}
              itemSecond={{
                firstIcon: <LinkLocationGame />,
                firstText: "Указывать ссылку на локацию по карте",
              }}
            />
            <img
              loading="lazy"
              src={organizer10}
              className={styles.Organizer10Img}
              alt="config"
            />
          </div>
          <div className={styles.Cards}>
            <div className={styles.Card}>
              <img
                loading="lazy"
                src={organizer11}
                className={styles.Organizer11Img}
                alt="host"
              />
              <div className={classNames(styles.Text, styles.TextPosition)}>
                Назначить пользователя ведущим игры
              </div>
            </div>
            <div className={styles.Card}>
              <img
                loading="lazy"
                src={organizer12}
                className={styles.Organizer12Img}
                alt="customClub"
              />
              <div className={classNames(styles.Text, styles.TextPosition)}>
                Отдельная страница собственного клуба с кастомным оформлением
              </div>
            </div>
            <div className={styles.Card}>
              <img
                loading="lazy"
                src={organizer13}
                className={styles.Organizer13Img}
                alt="public"
              />
              <div className={classNames(styles.Text, styles.TextPosition)}>
                Публичная страница с информацией по созданной игре
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { OrganizerSection };
