/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Action = {
  __typename?: 'Action';
  events: Array<Event>;
  id: Scalars['Float'];
  name: ActionEnum;
};

/** Action enum */
export enum ActionEnum {
  CarAccidentDeadResolving = 'CarAccidentDeadResolving',
  CarAccidentSaveResolving = 'CarAccidentSaveResolving',
  CitizenReVoting = 'CitizenReVoting',
  CitizenVoting = 'CitizenVoting',
  CityKilling = 'CityKilling',
  CityNominating = 'CityNominating',
  CityWon = 'CityWon',
  CommissionerChecking = 'CommissionerChecking',
  DoctorHealing = 'DoctorHealing',
  DonMafiaChecking = 'DonMafiaChecking',
  Foul = 'Foul',
  KillingAtWhoreHouse = 'KillingAtWhoreHouse',
  MafiaKilling = 'MafiaKilling',
  MafiaWon = 'MafiaWon',
  ManiacKilling = 'ManiacKilling',
  ManiacWon = 'ManiacWon',
  NobodyWon = 'NobodyWon',
  RoleSetting = 'RoleSetting',
  WhoreFucking = 'WhoreFucking'
}

export type AddGameIterationDto = {
  gameId: Scalars['Float'];
  name: Scalars['String'];
};

export type BasicIdDto = {
  id: Scalars['Float'];
};

export type CalculationWinnerDto = {
  __typename?: 'CalculationWinnerDto';
  scores: Array<ScorePoint>;
  sum: Scalars['Float'];
  user: User;
};

/** Car accident resolving action enum */
export enum CarAccidentResolvingAction {
  CarAccidentDeadResolving = 'CarAccidentDeadResolving',
  CarAccidentSaveResolving = 'CarAccidentSaveResolving'
}

export type City = {
  __typename?: 'City';
  avalableGames: Array<Game>;
  backgroundImage?: Maybe<Scalars['String']>;
  games: Array<Game>;
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type Club = {
  __typename?: 'Club';
  actualGames: Array<Game>;
  archivedGames: Array<Game>;
  backgroundImage?: Maybe<Scalars['String']>;
  commonRatings: Array<CommonRating>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  logoImage?: Maybe<Scalars['String']>;
  members: Array<User>;
  name: Scalars['String'];
  organizer: User;
  roleRatings: Array<RoleRating>;
  telegramLink?: Maybe<Scalars['String']>;
};

export type CommissionerRoleConfig = {
  __typename?: 'CommissionerRoleConfig';
  count?: Maybe<Scalars['Float']>;
  foundMafia: Scalars['Float'];
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
};

export type CommonConfig = {
  __typename?: 'CommonConfig';
  isRoleSettingBasedOnPlayer?: Maybe<Scalars['Boolean']>;
  isSportMafia: Scalars['Boolean'];
  sittingRoleType: SittingRoleTypeEnum;
};

export type CommonRating = {
  __typename?: 'CommonRating';
  allGames: Scalars['Float'];
  club: Club;
  id: Scalars['Float'];
  loseGames: Scalars['Float'];
  rating: Scalars['Float'];
  ratingPlace: Scalars['Float'];
  roleRatings: Array<RoleRating>;
  scoreCount: Scalars['Float'];
  user: User;
  winGames: Scalars['Float'];
  winPercentage: Scalars['Float'];
};

export type CommonRatingPage = {
  __typename?: 'CommonRatingPage';
  count: Scalars['Float'];
  data: Array<CommonRating>;
};

export type CommonRoleConfig = {
  __typename?: 'CommonRoleConfig';
  count?: Maybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
};

export type CreateClubDto = {
  backgroundImage?: InputMaybe<Scalars['String']>;
  logoImage?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  telegramLink?: InputMaybe<Scalars['String']>;
};

export type CreateUserDto = {
  email: Scalars['String'];
  isTermsAccepted: Scalars['Boolean'];
  password: Scalars['String'];
  secretWord: Scalars['String'];
};

export type DeathReasonDto = {
  __typename?: 'DeathReasonDto';
  action: Action;
  playerActor: Player;
  round: IterationRound;
};

export type DoctorRoleConfig = {
  __typename?: 'DoctorRoleConfig';
  citizenSaving: Scalars['Float'];
  count?: Maybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
};

export type DonMafiaRoleConfig = {
  __typename?: 'DonMafiaRoleConfig';
  checkCommissioner: Scalars['Float'];
  count?: Maybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
};

export type Event = {
  __typename?: 'Event';
  action: Action;
  date: Scalars['DateTime'];
  id: Scalars['Float'];
  iteration: Iteration;
  killedPlayers: Array<Player>;
  playerActors: Array<Player>;
  playerTargets: Array<Player>;
  round?: Maybe<IterationRound>;
  scores: Array<ScorePoint>;
};

export type FeedbackInputDto = {
  comment: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  token: Scalars['String'];
};

export type Game = {
  __typename?: 'Game';
  address: Scalars['String'];
  approvedRequests: Array<GameRequest>;
  city: City;
  config: GameConfig;
  cost: Scalars['Float'];
  currentUserOrderStatus?: Maybe<OrderStatusEnum>;
  date: Scalars['DateTime'];
  description: Scalars['String'];
  filteredPlayers: Array<Player>;
  id: Scalars['Float'];
  iterations: Array<Iteration>;
  locationLink: Scalars['String'];
  moderators: Array<User>;
  name: Scalars['String'];
  orders: Array<Order>;
  owner: User;
  paymentWaitingRequests: Array<GameRequest>;
  pendingRequests: Array<GameRequest>;
  playReadyRequests: Array<GameRequest>;
  playerCount: Scalars['Float'];
  players: Array<Player>;
  status: GameStatus;
  terminatedRequests: Array<GameRequest>;
  users: Array<User>;
};

export type GameConfig = {
  __typename?: 'GameConfig';
  commonConfig: CommonConfig;
  config: RoleConfig;
  games: Array<Game>;
  id: Scalars['Float'];
  isRemoved: Scalars['Boolean'];
  iterations: Array<Iteration>;
  name: Scalars['String'];
  organizer: User;
  totalPlayersCount: Scalars['Float'];
};

export type GameRequest = {
  __typename?: 'GameRequest';
  id: Scalars['Float'];
  isPlaying: Scalars['Boolean'];
  order: Order;
  user: User;
};

export type GameStatus = {
  __typename?: 'GameStatus';
  games: Array<Game>;
  id: Scalars['Float'];
  iterations: Array<Iteration>;
  name: GameStatusEnum;
};

/** Game status types */
export enum GameStatusEnum {
  Active = 'Active',
  Announced = 'Announced',
  Declined = 'Declined',
  Deleted = 'Deleted',
  Draft = 'Draft',
  Terminated = 'Terminated'
}

export type InitCommissionerRoleConfig = {
  count?: InputMaybe<Scalars['Float']>;
  foundMafia: Scalars['Float'];
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
};

export type InitCommonConfigDto = {
  isSportMafia?: Scalars['Boolean'];
  sittingRoleType: SittingRoleTypeEnum;
};

export type InitCommonRoleConfig = {
  count?: InputMaybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
};

export type InitConfigDto = {
  commonConfig?: InitCommonConfigDto;
  config: InitRoleConfigDto;
  name: Scalars['String'];
};

export type InitDoctorRoleConfig = {
  citizenSaving: Scalars['Float'];
  count?: InputMaybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
};

export type InitDonMafiaRoleConfig = {
  checkCommissioner: Scalars['Float'];
  count?: InputMaybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
};

export type InitGameDto = {
  address?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  configId: Scalars['Float'];
  cost: Scalars['Float'];
  date: Scalars['String'];
  description: Scalars['String'];
  locationLink: Scalars['String'];
  moderatorIds: Array<Scalars['Float']>;
  name: Scalars['String'];
  playerCount: Scalars['Float'];
};

export type InitMafiaRoleConfig = {
  count?: InputMaybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
  openedAsCommissioner: Scalars['Float'];
};

export type InitManiacRoleConfig = {
  count?: InputMaybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
  mafiaKilling: Scalars['Float'];
};

export type InitRoleConfigDto = {
  Citizen: InitCommonRoleConfig;
  Commissioner?: InputMaybe<InitCommissionerRoleConfig>;
  Doctor?: InputMaybe<InitDoctorRoleConfig>;
  DonMafia?: InputMaybe<InitDonMafiaRoleConfig>;
  Mafia: InitMafiaRoleConfig;
  Maniac?: InputMaybe<InitManiacRoleConfig>;
  Whore?: InputMaybe<InitWhoreRoleConfig>;
};

export type InitWhoreRoleConfig = {
  blackRoleKilling: Scalars['Float'];
  citizenSaving: Scalars['Float'];
  count?: InputMaybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
};

export type Iteration = {
  __typename?: 'Iteration';
  config: GameConfig;
  events: Array<Event>;
  game: Game;
  id: Scalars['Float'];
  name: Scalars['String'];
  players?: Maybe<Array<Player>>;
  rounds: Array<IterationRound>;
  state: IterationStateEnum;
  stateData: IterationStateData;
  status: GameStatus;
};

export type IterationRound = {
  __typename?: 'IterationRound';
  events: Array<Event>;
  id: Scalars['Float'];
  iteration: Iteration;
  number: Scalars['Float'];
  terminated: Scalars['Boolean'];
  type: RoundTypeEnum;
};

export type IterationStateData = {
  __typename?: 'IterationStateData';
  actors: Array<Player>;
  isRoleDead: Scalars['Boolean'];
  killingEvents: Array<Event>;
  rolesCalculation: Array<RolesCalculationDataItem>;
  roundType?: Maybe<RoundTypeEnum>;
  showEvents: Array<Event>;
  state: IterationStateEnum;
  targets: Array<Player>;
  vacantRoles: Array<PlayerRoleEnum>;
  winnerRole?: Maybe<PlayerRoleEnum>;
};

/** Iteration state enum */
export enum IterationStateEnum {
  Canceled = 'Canceled',
  CarAccident = 'CarAccident',
  Commissioner = 'Commissioner',
  DayComplete = 'DayComplete',
  Doctor = 'Doctor',
  DonMafia = 'DonMafia',
  Draft = 'Draft',
  Finished = 'Finished',
  Mafia = 'Mafia',
  Maniac = 'Maniac',
  NightComplete = 'NightComplete',
  Nominating = 'Nominating',
  Revoting = 'Revoting',
  SetCommissioner = 'SetCommissioner',
  SetDoctor = 'SetDoctor',
  SetDonMafia = 'SetDonMafia',
  SetMafia = 'SetMafia',
  SetManiac = 'SetManiac',
  SetPlayerRole = 'SetPlayerRole',
  SetRoleComplete = 'SetRoleComplete',
  SetRolesTable = 'SetRolesTable',
  SetWhore = 'SetWhore',
  Started = 'Started',
  Voting = 'Voting',
  Whore = 'Whore',
  Winner = 'Winner'
}

export type MafiaRoleConfig = {
  __typename?: 'MafiaRoleConfig';
  count?: Maybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
  openedAsCommissioner: Scalars['Float'];
};

export type ManiacRoleConfig = {
  __typename?: 'ManiacRoleConfig';
  count?: Maybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
  mafiaKilling: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Event day add new */
  addDayEvent: Array<Event>;
  /** Event add new foul */
  addFoulEvent: Event;
  /** Game config adding */
  addGameConfig: Iteration;
  /** Game iteration adding */
  addGameIteration: Iteration;
  /** Game assign user */
  addGamePlayer: Game;
  /** Add feedback from landing */
  addLandingFeedback?: Maybe<Scalars['Boolean']>;
  /** Event night add new */
  addNightEvent: Array<Event>;
  /** Calculate winner */
  calculateGameWinner: Array<CalculationWinnerDto>;
  /** Iteration complete role setting */
  completeIterationRoleSetting: IterationStateData;
  /** User confirm auth by code */
  confirmAuthByCode: User;
  /** User confirm change email code */
  confirmChangeEmail: Scalars['Boolean'];
  /** Club create */
  createClub: Club;
  /** User create */
  createUser: User;
  /** Delete game */
  deleteGameById: Game;
  /** Game config removing */
  deleteGameConfig: Scalars['Boolean'];
  /** User get auth code */
  getAuthCode: Scalars['Boolean'];
  /** User get change email auth code */
  getChangeEmailCode: Scalars['Boolean'];
  /** Order create order link */
  getOrderLink?: Maybe<Scalars['String']>;
  /** Game creation */
  initGame: Game;
  /** Iteration action rollback */
  iterationActionRevert: IterationStateData;
  /** Iteration action */
  iterationAddAction: IterationStateData;
  /** Iteration cancel */
  iterationCancel: IterationStateData;
  /** Iteration vote */
  iterationCarAccidentResolve: IterationStateData;
  /** Iteration complete */
  iterationComplete: IterationStateData;
  /** Iteration complete day */
  iterationCompleteDay: IterationStateData;
  /** Iteration complete night */
  iterationCompleteNight: IterationStateData;
  /** Iteration nominate players */
  iterationNominatePlayers: IterationStateData;
  /** Iteration rollback */
  iterationRevert: IterationStateData;
  /** Iteration role action */
  iterationRoleAction: IterationStateData;
  /** Iteration vote */
  iterationVote: IterationStateData;
  /** User get auth token */
  login: User;
  /** User get auth token by telegram user data */
  loginByTelegram: User;
  /** User logout */
  logout: Scalars['Boolean'];
  /** Recalculate user rating considering all user games in the club */
  recalculateUserRating: CommonRating;
  /** User recover password */
  recoverPasswordLink: Scalars['Boolean'];
  /** Event remove foul */
  removeFoulEvent: Scalars['Boolean'];
  /** Iteration deleting */
  removeIterationById: Scalars['Boolean'];
  /** Remove player */
  removePlayer: Scalars['Boolean'];
  /** Removing player score */
  removePlayerScore: Scalars['Boolean'];
  /** User send recovery password link */
  sendRecoveryPasswordLink: Scalars['Boolean'];
  /** Game set config */
  setGameConfig: Scalars['Boolean'];
  /** Iteration set config */
  setIterationConfig: Scalars['Boolean'];
  /** Iteration set role for player */
  setIterationPlayerRole: IterationStateData;
  /** Iteration set player for role */
  setIterationRolePlayer: IterationStateData;
  /** Iteration set roles for players */
  setIterationRolesTable: IterationStateData;
  /** Setting player score */
  setPlayerScorePoint: ScorePoint;
  /** Setting player role */
  setRoleByPlayerId: Player;
  /** User set telegram account */
  setTelegramAccount: Scalars['Boolean'];
  /** User signup */
  signup: User;
  /** Iteration starting */
  startIterationById: Scalars['Boolean'];
  /** Iteration start role setting */
  startIterationRoleSetting: IterationStateData;
  /** Game unAssign user */
  unAssignUser: Game;
  /** Game update */
  updateAnnouncedGame: Game;
  /** City update list */
  updateCityList: Scalars['Boolean'];
  /** Club update */
  updateClub: Scalars['Boolean'];
  /** Game update status */
  updateGameStatus: Game;
  /** Order update status by game request id */
  updateOrder: Order;
  /** Update player numbers */
  updatePlayerNumbers: Scalars['Boolean'];
  /** User update */
  updateUser: User;
  /** User reset password */
  updateUserPassword: Scalars['Boolean'];
  /** User update systemRole */
  updateUserRole: User;
};


export type MutationAddDayEventArgs = {
  newDayEventDto: NewDayEventDto;
};


export type MutationAddFoulEventArgs = {
  iterationId: Scalars['Float'];
  playerId: Scalars['Float'];
};


export type MutationAddGameConfigArgs = {
  initConfigDto: InitConfigDto;
};


export type MutationAddGameIterationArgs = {
  addIterationData: AddGameIterationDto;
};


export type MutationAddGamePlayerArgs = {
  gameId: Scalars['Float'];
};


export type MutationAddLandingFeedbackArgs = {
  feedback: FeedbackInputDto;
};


export type MutationAddNightEventArgs = {
  newNightEventDto: NewNightEventDto;
};


export type MutationCalculateGameWinnerArgs = {
  gameId: Scalars['Float'];
};


export type MutationCompleteIterationRoleSettingArgs = {
  iterationId: Scalars['Float'];
};


export type MutationConfirmAuthByCodeArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
};


export type MutationConfirmChangeEmailArgs = {
  code: Scalars['String'];
  newEmail: Scalars['String'];
};


export type MutationCreateClubArgs = {
  clubData: CreateClubDto;
};


export type MutationCreateUserArgs = {
  userData: CreateUserDto;
};


export type MutationDeleteGameByIdArgs = {
  gameId: Scalars['Float'];
};


export type MutationDeleteGameConfigArgs = {
  gameConfigId: Scalars['Float'];
};


export type MutationGetAuthCodeArgs = {
  email: Scalars['String'];
};


export type MutationGetChangeEmailCodeArgs = {
  email: Scalars['String'];
};


export type MutationGetOrderLinkArgs = {
  gameId: Scalars['Float'];
};


export type MutationInitGameArgs = {
  gameData: InitGameDto;
};


export type MutationIterationActionRevertArgs = {
  newDayEventDto: NewDayEventDto;
};


export type MutationIterationAddActionArgs = {
  newDayEventDto: NewDayEventDto;
};


export type MutationIterationCancelArgs = {
  iterationId: Scalars['Float'];
};


export type MutationIterationCarAccidentResolveArgs = {
  actorIds: Array<Scalars['Float']>;
  iterationId: Scalars['Float'];
  resolveActionType: CarAccidentResolvingAction;
  targetIds: Array<Scalars['Float']>;
};


export type MutationIterationCompleteArgs = {
  iterationId: Scalars['Float'];
};


export type MutationIterationCompleteDayArgs = {
  iterationId: Scalars['Float'];
};


export type MutationIterationCompleteNightArgs = {
  iterationId: Scalars['Float'];
};


export type MutationIterationNominatePlayersArgs = {
  iterationId: Scalars['Float'];
  playerIds: Array<Scalars['Float']>;
};


export type MutationIterationRevertArgs = {
  iterationId: Scalars['Float'];
};


export type MutationIterationRoleActionArgs = {
  iterationId: Scalars['Float'];
  targetIds: Array<Scalars['Float']>;
};


export type MutationIterationVoteArgs = {
  actorIds: Array<Scalars['Float']>;
  iterationId: Scalars['Float'];
  targetId?: InputMaybe<Scalars['Float']>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginByTelegramArgs = {
  initData: Scalars['String'];
};


export type MutationRecalculateUserRatingArgs = {
  clubId: Scalars['Float'];
  userId: Scalars['Float'];
};


export type MutationRecoverPasswordLinkArgs = {
  recoverUserPassword: RecoverUserPassword;
};


export type MutationRemoveFoulEventArgs = {
  foulEventId: Scalars['Float'];
};


export type MutationRemoveIterationByIdArgs = {
  iterationId: Scalars['Float'];
};


export type MutationRemovePlayerArgs = {
  playerId: Scalars['Float'];
};


export type MutationRemovePlayerScoreArgs = {
  scoreId: Scalars['Float'];
};


export type MutationSendRecoveryPasswordLinkArgs = {
  recoverUserPasswordRequest: RecoverUserPasswordRequest;
};


export type MutationSetGameConfigArgs = {
  configId: Scalars['Float'];
  gameId: Scalars['Float'];
};


export type MutationSetIterationConfigArgs = {
  configId: Scalars['Float'];
  iterationId: Scalars['Float'];
};


export type MutationSetIterationPlayerRoleArgs = {
  iterationId: Scalars['Float'];
  roleName: PlayerRoleEnum;
};


export type MutationSetIterationRolePlayerArgs = {
  iterationId: Scalars['Float'];
  playerIds: Array<Scalars['Float']>;
};


export type MutationSetIterationRolesTableArgs = {
  iterationId: Scalars['Float'];
  players: Array<SetRoleTableItemDto>;
};


export type MutationSetPlayerScorePointArgs = {
  comment: Scalars['String'];
  playerId: Scalars['Float'];
  points: Scalars['Float'];
};


export type MutationSetRoleByPlayerIdArgs = {
  playerId: Scalars['Float'];
  playerRoleId?: InputMaybe<Scalars['Float']>;
};


export type MutationSetTelegramAccountArgs = {
  initData: Scalars['String'];
};


export type MutationSignupArgs = {
  userData: CreateUserDto;
};


export type MutationStartIterationByIdArgs = {
  iterationId: Scalars['Float'];
  requestIds: Array<Scalars['Float']>;
};


export type MutationStartIterationRoleSettingArgs = {
  iterationId: Scalars['Float'];
};


export type MutationUnAssignUserArgs = {
  gameId: Scalars['Float'];
};


export type MutationUpdateAnnouncedGameArgs = {
  gameData: UpdateGameDto;
  gameId: Scalars['Float'];
};


export type MutationUpdateCityListArgs = {
  cityList: Array<UpdateCityDto>;
};


export type MutationUpdateClubArgs = {
  clubData: UpdateClubDto;
};


export type MutationUpdateGameStatusArgs = {
  gameId: Scalars['Float'];
  statusId: Scalars['Float'];
};


export type MutationUpdateOrderArgs = {
  orderId: Scalars['Float'];
  orderStatus: OrderStatusEnum;
};


export type MutationUpdatePlayerNumbersArgs = {
  players: Array<UpdatePlayerNumberDto>;
};


export type MutationUpdateUserArgs = {
  userData: UpdateUserDto;
};


export type MutationUpdateUserPasswordArgs = {
  resetPasswordData: ResetUserPassword;
};


export type MutationUpdateUserRoleArgs = {
  systemRole: UpdateUserSystemRolesDto;
  userId: Scalars['Float'];
};

export type NewDayEventDto = {
  iteration: BasicIdDto;
  playerActors: Array<BasicIdDto>;
  playerTargets: Array<BasicIdDto>;
  round: BasicIdDto;
};

export type NewNightEventDto = {
  action: BasicIdDto;
  iteration: BasicIdDto;
  playerActor: BasicIdDto;
  playerTargets: Array<BasicIdDto>;
  round: BasicIdDto;
};

export type Order = {
  __typename?: 'Order';
  date: Scalars['DateTime'];
  game: Game;
  id: Scalars['Float'];
  orderOwner: User;
  payments: Array<Payment>;
  requests: Array<GameRequest>;
  status: OrderStatusEnum;
};

export type OrderPage = {
  __typename?: 'OrderPage';
  count: Scalars['Float'];
  data: Array<Order>;
};

/** Order status enum */
export enum OrderStatusEnum {
  Approved = 'Approved',
  CancelPending = 'CancelPending',
  Canceled = 'Canceled',
  Declined = 'Declined',
  Paid = 'Paid',
  PaidMark = 'PaidMark',
  Pending = 'Pending',
  Terminated = 'Terminated'
}

export type OrdersSearchParamsDto = {
  date?: InputMaybe<SearchOrderingValueEnum>;
  skip?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<SearchOrderingValueEnum>;
  take?: InputMaybe<Scalars['Float']>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['Float'];
  order: Order;
  paymentId: Scalars['String'];
  paymentStatus: PaymentStatusEnum;
  paymentToken: Scalars['String'];
  paymentURL: Scalars['String'];
  user: User;
};

/** Payment status enum */
export enum PaymentStatusEnum {
  Canceled = 'Canceled',
  Confirmed = 'Confirmed',
  Expired = 'Expired',
  New = 'New',
  Paid = 'Paid',
  Refunded = 'Refunded',
  Rejected = 'Rejected',
  Reversed = 'Reversed'
}

export type Player = {
  __typename?: 'Player';
  deadlyEvent?: Maybe<Event>;
  deathReason?: Maybe<DeathReasonDto>;
  foulEvents: Array<Event>;
  game: Game;
  gotEvents: Array<Event>;
  id: Scalars['Float'];
  isDead: Scalars['Boolean'];
  iteration?: Maybe<Iteration>;
  number: Scalars['Float'];
  playerRole?: Maybe<PlayerRole>;
  scores: Array<ScorePoint>;
  sentEvents: Array<Event>;
  status: PlayerStatusEnum;
  user: User;
};

export type PlayerRole = {
  __typename?: 'PlayerRole';
  action: Action;
  id: Scalars['Float'];
  name: PlayerRoleEnum;
  players: Array<Player>;
  roleRatings: Array<RoleRating>;
};

/** Player role types */
export enum PlayerRoleEnum {
  Citizen = 'Citizen',
  City = 'City',
  Commissioner = 'Commissioner',
  Doctor = 'Doctor',
  DonMafia = 'DonMafia',
  Mafia = 'Mafia',
  MafiaMember = 'MafiaMember',
  Maniac = 'Maniac',
  Whore = 'Whore'
}

/** Player status enum */
export enum PlayerStatusEnum {
  Alive = 'Alive',
  Dead = 'Dead',
  Disqualified = 'Disqualified'
}

export type Query = {
  __typename?: 'Query';
  /** Action find by id */
  getActionById?: Maybe<Action>;
  /** Action find by id */
  getActionListGroupedByRoles?: Maybe<Action>;
  /** available games */
  getActiveGames: Array<Game>;
  /** City find by id */
  getCityById?: Maybe<City>;
  /** City get list */
  getCityList: Array<City>;
  /** Get club */
  getClubById?: Maybe<Club>;
  /** Get all club list */
  getClubList?: Maybe<Array<Club>>;
  /** Event find by id */
  getEventById?: Maybe<Event>;
  /** Game find by id */
  getGameById?: Maybe<Game>;
  /** GameConfig find by id */
  getGameConfigById?: Maybe<GameConfig>;
  /** GameConfig get available game configs */
  getGameConfigs?: Maybe<Array<GameConfig>>;
  /** GameRequest get list by game id */
  getGameRequestsByGameId: Array<GameRequest>;
  /** Game status find all */
  getGameStatues: Array<GameStatus>;
  /** Iteration find by id */
  getIterationById?: Maybe<Iteration>;
  /** Iteration round find by id */
  getIterationRoundById?: Maybe<IterationRound>;
  /** Order get list by game id */
  getOrdersByGameId: Array<Order>;
  /** GameConfig get organizer config list */
  getOrganizerGameConfigs?: Maybe<Array<GameConfig>>;
  /** User get owned games */
  getOrganizerGames: Array<Game>;
  /** Order get list by organizer id */
  getOrganizerOrders: OrderPage;
  /** Get own club */
  getOwnClub?: Maybe<Club>;
  /** Player find by id */
  getPlayerById?: Maybe<Player>;
  /** Player role find all */
  getPlayerRoles: Array<PlayerRole>;
  /** Get club common rating list */
  getRatingByClubId: CommonRatingPage;
  /** SystemRoles get list */
  getSystemRoles: Array<SystemRole>;
  getToken: Scalars['String'];
  /** Get total user rating */
  getTotalUserRatingByClubId?: Maybe<TotalCommonRating>;
  /** Users get list */
  getUserByFilter: Array<User>;
  /** User find by id */
  getUserById?: Maybe<User>;
  /** User get by auth token */
  getUserByToken?: Maybe<User>;
  /** User get games */
  getUserGames: Array<Game>;
  /** User find list */
  getUserList: Array<User>;
};


export type QueryGetActionByIdArgs = {
  actionId: Scalars['Float'];
};


export type QueryGetActiveGamesArgs = {
  city: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
};


export type QueryGetCityByIdArgs = {
  cityId: Scalars['Float'];
};


export type QueryGetClubByIdArgs = {
  clubId: Scalars['Float'];
};


export type QueryGetEventByIdArgs = {
  eventId: Scalars['Float'];
};


export type QueryGetGameByIdArgs = {
  gameId: Scalars['Float'];
};


export type QueryGetGameConfigByIdArgs = {
  configId: Scalars['Float'];
};


export type QueryGetGameConfigsArgs = {
  gameId: Scalars['Float'];
};


export type QueryGetGameRequestsByGameIdArgs = {
  gameId: Scalars['Float'];
};


export type QueryGetIterationByIdArgs = {
  iterationId: Scalars['Float'];
};


export type QueryGetIterationRoundByIdArgs = {
  iterationRoundId: Scalars['Float'];
};


export type QueryGetOrdersByGameIdArgs = {
  gameId: Scalars['Float'];
};


export type QueryGetOrganizerOrdersArgs = {
  gameDate?: InputMaybe<Scalars['String']>;
  gameId?: InputMaybe<Scalars['Float']>;
  ordersSearchParams?: InputMaybe<OrdersSearchParamsDto>;
  status?: InputMaybe<OrderStatusEnum>;
};


export type QueryGetPlayerByIdArgs = {
  playerId: Scalars['Float'];
};


export type QueryGetRatingByClubIdArgs = {
  clubId: Scalars['Float'];
  ratingSearchParams?: InputMaybe<RatingSearchParamsDto>;
};


export type QueryGetTotalUserRatingByClubIdArgs = {
  userId: Scalars['Float'];
};


export type QueryGetUserByFilterArgs = {
  userFiler: UserFilterDto;
};


export type QueryGetUserByIdArgs = {
  userId: Scalars['Float'];
};


export type QueryGetUserListArgs = {
  searchString: Scalars['String'];
};

export type RatingSearchParamsDto = {
  allGames?: InputMaybe<SearchOrderingValueEnum>;
  loseGames?: InputMaybe<SearchOrderingValueEnum>;
  rating?: InputMaybe<SearchOrderingValueEnum>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  winGames?: InputMaybe<SearchOrderingValueEnum>;
  winPercentage?: InputMaybe<SearchOrderingValueEnum>;
};

export type RecoverUserPassword = {
  newPassword: Scalars['String'];
  recoveryToken: Scalars['String'];
};

export type RecoverUserPasswordRequest = {
  email: Scalars['String'];
};

export type ResetUserPassword = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type RoleConfig = {
  __typename?: 'RoleConfig';
  Citizen: CommonRoleConfig;
  Commissioner?: Maybe<CommissionerRoleConfig>;
  Doctor?: Maybe<DoctorRoleConfig>;
  DonMafia?: Maybe<DonMafiaRoleConfig>;
  Mafia: MafiaRoleConfig;
  Maniac?: Maybe<ManiacRoleConfig>;
  Whore?: Maybe<WhoreRoleConfig>;
};

export type RoleRating = {
  __typename?: 'RoleRating';
  club: Club;
  commonRating: CommonRating;
  id: Scalars['Float'];
  loses: Scalars['Float'];
  role: PlayerRole;
  user: User;
  wins: Scalars['Float'];
};

export type RolesCalculationDataItem = {
  __typename?: 'RolesCalculationDataItem';
  deadPlayerIds: Array<Scalars['Float']>;
  role: PlayerRoleEnum;
  takenPlayerIds: Array<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Round type enum */
export enum RoundTypeEnum {
  Day = 'Day',
  Night = 'Night'
}

export type ScorePoint = {
  __typename?: 'ScorePoint';
  comment: Scalars['String'];
  event?: Maybe<Event>;
  id: Scalars['Float'];
  player: Player;
  points: Scalars['Float'];
};

/** Search ordering */
export enum SearchOrderingValueEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SetRoleTableItemDto = {
  id: Scalars['Float'];
  role: PlayerRoleEnum;
};

/** Sitting role type */
export enum SittingRoleTypeEnum {
  BasedOnPlayer = 'BasedOnPlayer',
  BasedOnRole = 'BasedOnRole',
  Table = 'Table'
}

export type SystemRole = {
  __typename?: 'SystemRole';
  id: Scalars['Float'];
  name: SystemRoleEnum;
  users: Array<User>;
};

/** System role types */
export enum SystemRoleEnum {
  Admin = 'Admin',
  CityCommonUser = 'CityCommonUser',
  CommonUser = 'CommonUser',
  MafiaCommonUser = 'MafiaCommonUser',
  Moderator = 'Moderator',
  Organizer = 'Organizer'
}

export type TotalCommonRating = {
  __typename?: 'TotalCommonRating';
  allGames: Scalars['Float'];
  loseGames: Scalars['Float'];
  user: User;
  winGames: Scalars['Float'];
};

export type UpdateCityDto = {
  backgroundImage: Scalars['String'];
  id: Scalars['Float'];
};

export type UpdateClubDto = {
  backgroundImage?: InputMaybe<Scalars['String']>;
  clubId: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  logoImage?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  telegramLink?: InputMaybe<Scalars['String']>;
};

export type UpdateGameDto = {
  address: Scalars['String'];
  city: Scalars['String'];
  configId: Scalars['Float'];
  cost: Scalars['Float'];
  date?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  locationLink: Scalars['String'];
  moderatorIds?: InputMaybe<Array<Scalars['Float']>>;
  name?: InputMaybe<Scalars['String']>;
  playerCount?: InputMaybe<Scalars['Float']>;
};

export type UpdatePlayerNumberDto = {
  id: Scalars['Float'];
  number: Scalars['Float'];
};

export type UpdateUserDto = {
  aboutMe?: InputMaybe<Scalars['String']>;
  clubTelegram?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  imgAvatar?: InputMaybe<Scalars['String']>;
  imgAvatarProfile?: InputMaybe<Scalars['String']>;
  isTermsAccepted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
};

export type UpdateUserSystemRolesDto = {
  systemRoles?: InputMaybe<Array<UserSystemRoleDto>>;
};

export type User = {
  __typename?: 'User';
  aboutMe?: Maybe<Scalars['String']>;
  clubTelegram?: Maybe<Scalars['String']>;
  clubsMember: Array<Club>;
  commonRatings: Array<CommonRating>;
  email?: Maybe<Scalars['String']>;
  gameConfigs: Array<GameConfig>;
  games: Array<Game>;
  id: Scalars['Float'];
  imgAvatar?: Maybe<Scalars['String']>;
  imgAvatarProfile?: Maybe<Scalars['String']>;
  isTermsAccepted: Scalars['Boolean'];
  login?: Maybe<Scalars['String']>;
  moderatedGames: Array<Game>;
  name?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  orders: Array<Order>;
  ownClub?: Maybe<Club>;
  ownedAvailableGames: Array<Game>;
  ownedGames: Array<Game>;
  password?: Maybe<Scalars['String']>;
  payments: Array<Payment>;
  phoneNumber?: Maybe<Scalars['String']>;
  players: Array<Player>;
  requests: Array<GameRequest>;
  roleRatings: Array<RoleRating>;
  surname?: Maybe<Scalars['String']>;
  systemRoles: Array<SystemRole>;
  telegramId?: Maybe<Scalars['Float']>;
  telegramPhoto?: Maybe<Scalars['String']>;
  telegramUserName?: Maybe<Scalars['String']>;
};

export type UserFilterDto = {
  systemRoles?: InputMaybe<Array<UserSystemRoleDto>>;
};

export type UserSystemRoleDto = {
  id?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

export type WhoreRoleConfig = {
  __typename?: 'WhoreRoleConfig';
  blackRoleKilling: Scalars['Float'];
  citizenSaving: Scalars['Float'];
  count?: Maybe<Scalars['Float']>;
  isAlive: Scalars['Float'];
  isDead: Scalars['Float'];
};

export type AddLandingFeedbackMutationVariables = Exact<{
  feedback: FeedbackInputDto;
}>;


export type AddLandingFeedbackMutation = { __typename?: 'Mutation', addLandingFeedback?: boolean | null };


export const AddLandingFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddLandingFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"feedback"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FeedbackInputDto"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addLandingFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"feedback"},"value":{"kind":"Variable","name":{"kind":"Name","value":"feedback"}}}]}]}}]} as unknown as DocumentNode<AddLandingFeedbackMutation, AddLandingFeedbackMutationVariables>;