import ReactDOM from "react-dom/client";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { GlobalStyles } from "@mui/material";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { onError } from "@apollo/client/link/error";
import { App } from "./App";
import { defaultMaterialTheme } from "./theme";

import "./index.css";

const domain =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:4000";

export const fonts = `
  @font-face {
    font-family: 'Literata';
    src: url('${domain}/fonts/Literata-Regular.woff2') format('woff2'), url('${domain}/fonts/Literata-Regular.woff') format('woff');
    font-weight:400;
    font-style:normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Literata';
    src: url('${domain}/fonts/Literata-Medium.woff2') format('woff2'), url('${domain}/fonts/Literata-Medium.woff') format('woff');
    font-weight:500;
    font-style:normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Literata';
    src: url('${domain}/fonts/Literata-SemiBold.woff2') format('woff2'), url('${domain}/fonts/Literata-SemiBold.woff') format('woff');
    font-weight:600;
    font-style:normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Literata';
    src: url('${domain}/fonts/Literata-Bold.woff2') format('woff2'), url('${domain}/fonts/Literata-Bold.woff') format('woff');
    font-weight:700;
    font-style:normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Literata';
    src: url('${domain}/fonts/Literata-ExtraBold.woff2') format('woff2'), url('${domain}/fonts/Literata-ExtraBold.woff') format('woff');
    font-weight:800;
    font-style:normal;
    font-display: block;
  }
  `;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://stargamers.io/graphql"
      : "http://localhost:4000/graphql",
  credentials: "include",
});

const link = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "Apollo-Require-Preflight": true,
    },
  };
});

const client = new ApolloClient({
  link: from([errorLink, link.concat(httpLink)]),
  cache: new InMemoryCache({}),
  connectToDevTools: true,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if ((document.getElementById("root") as HTMLElement).hasChildNodes()) {
  ReactDOM.hydrateRoot(
    document.getElementById("root") as HTMLElement,
    <ApolloProvider client={client}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <GlobalStyles styles={fonts} />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
} else {
  root.render(
    <ApolloProvider client={client}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <GlobalStyles styles={fonts} />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
}
