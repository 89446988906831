import * as React from "react";
import { BannerContent } from "./BannerContent";
import { ReactComponent as Lady } from "./images/lady.svg";
import { ReactComponent as City } from "./images/city.svg";

import styles from "./Banner.module.scss";

type IProps = {};

const Banner: React.FC<IProps> = () => {
  return (
    <>
      <div className={styles.Container}>
        <Lady className={styles.Lady} />
        <City className={styles.City} />
        <BannerContent />
      </div>
    </>
  );
};

export { Banner };
